import React from "react";

const Footer = () => {
  return (
    <div className="w-screen  text-center absolute bottom-0 py-4 bg-gray-200 text-gray-500 max-h-100 ">
      <p className="text-gray-700 text-sm lg:text-base">
        &copy; 2023 WatchSpot. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
