const articles = [
  {
    id: 1,
    title: "Seiko 5",
    content: `The Seiko 5 is a popular and affordable automatic watch. It is known for its durability,reliability, and style. It is powered by an automatic movement, 
    so it does not need to be wound manually. It is made from durable materials, such as stainless steel and mineral crystal. It is very affordable, making it a great option for those on a budget. However, 
    it does have some drawbacks. The Seiko 5 has a date window, which may be difficult to read for some people. It is also relatively small, so it may not be suitable for everyone. It does not have many features, 
    such as a chronograph or a water resistance rating. Overall, the Seiko 5 is a great value for the price. If you are looking for a durable, reliable, and affordable automatic watch, then the Seiko 5 is a great option. However, 
    if you are looking for a watch with more features, then you may want to consider a different watch.`,
    image:
      "https://images.unsplash.com/photo-1596516109370-29001ec8ec36?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80",
    subject: "Watch Review",
    specs: {
      movement: "Automatic",
      case: "Stainless Steel",
      caseDiameter: "42mm",
      waterResistance: "100m",
      strap: "Stainless Steel",
      crystal: "Hardlex",
      price: "$250",
    },
  },
  {
    id: 2,
    title: "Bulova Lunar Pilot",
    content:
      "The Bulova Lunar Pilot 43mm is a stylish and affordable chronograph watch that is inspired by the original Bulova watch that was worn on the moon by David Scott. The watch features a 43mm stainless steel case, a black dial with white sub-dials, and a date window at 3 o'clock. It is powered by a Bulova 96B22 automatic movement and has a water resistance of 50 meters. The watch is available with a black leather strap or a stainless steel bracelet. Overall, the Bulova Lunar Pilot 43mm is a great watch for anyone looking for a stylish and affordable chronograph. It is well-made, reliable, and has a classic design",
    image:
      "https://preview.redd.it/bulova-lunar-pilot-the-watch-that-turned-me-away-from-a-v0-ndaj6pkbyvm91.jpg?auto=webp&s=385c6b93ee628e21fa0aa98a89c168e3d2bd1c86",
    subject: "Watch Review",
    specs: {
      movement: "Quartz",
      case: "Stainless Steel",
      caseDiameter: "43mm",
      waterResistance: "50m",
      strap: "Leather",
      crystal: "Sapphire",
      price: "$650",
    },
  },
];

export default articles;
