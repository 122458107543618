import "./App.css";
import Navbar from "./components/Navbar/Navbar.jsx";
import Home from "./components/Home/Home.jsx";
import Hero from "./components/hero_section/Hero";
import Article from "./components/Article/Article";
import Article_Page from "./components/Article_Page/Article_Page";
import seiko from "./assets/seiko.jpg";
import bulova from "./assets/bulova2.jpg";
import rolex from "./assets/rolex.jpg";
import articles from "./articles.json";
import { Link } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import SharedLayout from "./components/SharedLayout/SharedLayout";
import Home2 from "./components/Home/Home2";

function App() {
  return (
    <div className="App">
      <div className="container">
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route
              path="/article_page/:productId"
              element={<Article_Page title="hello" />}
            />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;

/* <hr className="mt-4" />
        <h1 className="mt-8 mb-0 text-center text-xl  font-semibold ">
          The <span className="text-red-600 font-bold">latest</span> from
          WatchSpot
        </h1>
        <Article
          title="Seiko 5"
          content="lore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
          image={seiko}
          subject="Watch Review"
        />
        <Article
          title="Bulova Lunar Pilot"
          content="lore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
          image={bulova}
          subject="Watch Review"
        />
        <Article
          title="Rolex Submariner"
          content="lore ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation"
          image={rolex}
          subject="Watch Review"
        /> */
