import React from "react";
import "./Hero.css";
import Seiko from "../../assets/seiko.jpg";
import bulova from "../../assets/bulova2.jpg";
import { Link } from "react-router-dom";
import scroll from "../../assets/scroll.png";

function Hero() {
  return (
    <Link to="/article_page/1">
      <div className="featured-post lg:mt-36 text-center">
        <img src={bulova} alt="" className="h-200" />
        <div className="featured-details bg-white">
          <h2 className="mt-4 lg:mt-4 mx-4 ">
            <span className="feature-title-span block lg:mb-2 text-xl lg:text-2xl text-gray-500 font-medium">
              Introducing
            </span>
            <span className="feature-title-span block mb-2 lg:mb-0 text-2xl lg:text-3xl font-gray-800 font-bold">
              The Bulova Lunar Pilot
            </span>
          </h2>
          <h3 className="text-xl mb-3"> A watch that's out of this world</h3>
          <p className="m-auto lg:mt-0 lg:w-1/2 w-3/4 text-center">
            This watch is a reissue of the watch worn by astronaut David Scott
            during the Apollo 15 mission.
          </p>
        </div>
      </div>
    </Link>
  );
}

export default Hero;
