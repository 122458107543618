import { React, useState } from "react";
import "./Navbar.css";
import Logo from "../../assets/logo_3.png";

import { Routes, Route, Link } from "react-router-dom";

function Navbar() {
  const [hamburger, setHamburger] = useState("");
  const [mobileNav, setMobileNav] = useState("");

  const handleHamburger = () => {
    if (hamburger === "") {
      console.log("Clicked");
      setHamburger("change");
      setMobileNav("active");
    } else {
      setHamburger("");
      setMobileNav("");
    }
  };

  return (
    <header className="mb-0 pb-4 fixed top-0 left-0 bg-white">
      <div className="title-container">
        <Link to="/">
          <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <span className="logo-W">W</span>atchSpot
          </h1>
        </Link>
      </div>

      <div className="hamburger " onClick={handleHamburger}>
        <div className={"bar1 " + hamburger}></div>
        <div className={"bar2 " + hamburger}></div>
        <div className={"bar3 " + hamburger}></div>
      </div>
      <div className={"mobile-nav absolute top-0 " + mobileNav}>
        <ul className="mobile-nav-list">
          <li className="mobile-nav-item">
            <Link to="/">Home</Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Navbar;
