import React from "react";
import { Link } from "react-router-dom";

const Article = (props) => {
  return (
    <Link to={`/article_page/${props.productId}`}>
      <div className="bg-gray-100 lg:bg-white overflow-hidden mt-6 last-of-type:mb-8 md:w-3/4  xl:w-1/2 2xl:w-1/3 xl:pt-4 mx-auto">
        <div className="pb-8 md:pb-0">
          <div className="flex flex-col md:flex-row w-full">
            <img
              className="md:w-72 md:h-48 object-cover h-64 flex-shrink-0"
              src={props.image}
              alt="{"
            />
            <div className="text-content flex flex-col justify-center ml-2">
              <h3 className="text-sm font-bold text-gray-400 mx-4 mb-1 lg:text-lg pt-8 md:pt-0 ">
                {props.subject}
              </h3>
              <h2 className="text-xl lg:text-2xl font-semibold mx-4 text-gray-900 mb-0">
                {props.title}
              </h2>
              <p className="text-gray-700 text-sm lg:text-base mt-1s mx-4  lg:px-0">
                {props.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Article;
