import React from "react";
import { useParams, Link } from "react-router-dom";
import data from "../../data.js";
import { useEffect } from "react";

const ArticlePage = () => {
  const { productId } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class=" h-full md:px-4 py-16 lg:p-16">
      <div class="flex flex-col pb-8  sm:px-4">
        <div class="w-full max-w-7xl mx-auto lg:mt-20 mt-16">
          <h1 class="text-3xl lg:text-3xl font-bold mb-0 text-center">
            {data[productId].title}
          </h1>
          <h4 className="text-center text-gray-400 mb-8">15th May 2023</h4>
          <img
            class="w-full object-cover h-64 lg:h-80 mb-8 md:mt-"
            src={data[productId].image}
            alt="Image"
          />
          <div className="details mx-4">
            <p class="text-gray-700 text-base lg:text-lg mt-2  px-0 pt-2 space-y-2">
              {data[productId].content}
            </p>
          </div>
          {/* 
          <h3 class="text-md font-bold text-gray-400 mb-1 lg:text-lg ml-4 mt-8">
            Specifications
          </h3> */}

          {/* <ul class="list-disc list-none list-inside mb-4 ml-4">
            <li class="flex items-center">
              <span class="text-gray-400 mr-2">Case Diameter</span>
              {data[productId].specs.caseDiameter}
            </li>
            <li class="flex items-center">
              <span class="text-gray-400 mr-2">Movement</span>
              {data[productId].specs.movement}
            </li>
            <li class="flex items-center">
              <span class="text-gray-400 mr-2">Water Resistance</span>
              {data[productId].specs.waterResistance}
            </li>
            <li class="flex items-center">
              <span class="text-gray-400 mr-2">Crystal</span>
              {data[productId].specs.crystal}
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
