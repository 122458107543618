import React from "react";
import Hero from "../hero_section/Hero";
import Article from "../Article/Article";
import seiko from "../../assets/seiko.jpg";
import bulova from "../../assets/bulova2.jpg";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero />
      <div className="articles mb-24">
        <hr className="mt-4" />
        <h1 className="mt-8 mb-0 text-center text-xl  font-semibold ">
          The <span className="text-red-600 font-bold">latest</span> from
          WatchSpot
        </h1>
        <Article
          title="Seiko 5"
          content="lore ipsum dolor sit amet, consectetur adipiscing elit."
          image={seiko}
          subject="Watch Review"
          productId="0"
        />
        <Article
          title="Bulova Lunar Pilot"
          content="lore ipsum dolor sit amet, consectetur adipiscing elit."
          image={bulova}
          subject="Watch Review"
          productId="1"
        />
      </div>
    </div>
  );
};

export default Home;
